.email {
    padding-bottom: 20px;
    border-bottom: 2px solid var(--global-color-grayscale-cultured);
}

.templatedisplay {
    margin-bottom: 20px;
}

.resend {
    margin: 10px;
}

.status-btn {
    margin-top: 16px;
}

.history{
    margin-top: 4px;
}

.history ul {
    list-style-type: none;
    margin-top: 2px;
    padding-left: 0;
}

.historytext {
    font-size: 14px;
}

.paubox-hint {
    margin-top: 10px;
    font-size: 13px;
}