.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.padded {
  padding-bottom: 16px;
}

.status-icon {
  height: 24px;
  margin-left: 8px;
}
