
.level-dropdown-container {
  display: flex;
  flex-direction: row;
  gap: 36px;
  align-items: end;
}


.level-dropdown {
  max-width: 150px;
}
