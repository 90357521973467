.answer {
  margin-left: 24px;
  font-weight: bold;
}

.initial {
  margin-left: 24px;
}

.increased {
  color: var(--global-color-primary-steel-teal);
}

.decreased {
  color: var(--global-color-primary-cedar-chest);
}
