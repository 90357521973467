.header {
  cursor: pointer;
  border: solid 1px var(--global-color-primary-steel-teal);
  padding: 4px 16px;
  display: flex;
  align-items: center;
}

.arrow {
  height: 20px;
  margin-left: auto;
}

.content {
  height: 100%;
  overflow: hidden;

  padding: 16px;
  border: solid 1px var(--global-color-primary-steel-teal);
  border-top: none;

  transform: scaleY(1);
  transform-origin: top;
  transition: transform 0.25s ease;
}

.content-collapsed {
  transform: scaleY(0);
  height: 0;
  padding: 0;
}
