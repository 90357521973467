.header {
  margin-block-start: 0;
}

.container {
  margin-top: 16px;
}

.fieldset {
  margin-top: 0;
}

.btn-container {
  display: flex;
  gap: 16px;
  align-items: center;
}

.btn-container-2 {
  display: flex;
  gap: 16px;
  align-items: center;
}

.action-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.modal {
  width: 90%;
  max-width: unset;
}

.note {
  margin-top: 16px;
  padding-left: 8px;
  font-style: italic;
}

.table {
  margin-bottom: 16px;
  border-radius: 8px;
  border: solid black 1px;

  table-layout: auto;
  width: 100%;
}

.table tbody tr td {
  padding-top: 8px;
  padding-bottom: 8px;

  vertical-align: top;
}

/* break words in last two columns (cells), so auto width works correctly */
.table tbody tr td:nth-last-child(1),
.table tbody tr td:nth-last-child(2) {
  word-break: break-all;
}

.table tbody tr:nth-child(odd) td.changed {
  background: var(--global-color-cream);
}

.table tbody tr:nth-child(even) td.changed {
  background: #fff2c4;
}
