
.table {
    width: 100% !important; 
    margin: 0 auto 20px 0;
    border-color: var(--global-color-primary-mint);
}
.table tr:nth-child(odd) {
    background: var(--global-color-secondary-white);
}

.table tr:nth-child(even) {
    background: var(--global-color-grayscale-cultured);
}

.table th {
    background: var(--global-color-primary-mint);
}

.table td {
    padding: 0 10px;
    text-align: center;
}

.appointment-item {
    margin-bottom: 16px;
}