.container {
  display: flex;
  gap: 16px;
  align-items: end;

  margin-bottom: 24px;
}

.filter {
    max-width: 160px;
}

.input {
    max-width: 200px;
}

.input > div {
    max-height: 52px;
}

.btn {
    max-height: 52px;
    min-width: 48px;
}