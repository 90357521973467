.title {
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 8px;
}

.challenges-container {
  margin-top: 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.add-container {
  display: flex;
  gap: 20px;
  margin-bottom: 8px;

  align-items: flex-end;
}

.add-container > *:first-child {
  flex: 1;
  min-width: 100px;
}

.challenge {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 48px;
  align-items: end;

  margin-bottom: 16px;
}
