
.table {
  width: 100% !important;
  margin: 0 auto 20px 0;
  border-color: var(--global-color-primary-mint);
}
.table tr:nth-child(odd) {
  background: var(--global-color-secondary-white);
}

.table tr:nth-child(even) {
  background: var(--global-color-grayscale-cultured);
}

.table th {
  background: var(--global-color-primary-mint);
}

.table td {
  padding: 8px 10px;
  white-space: pre-line;
}

.table td:last-child {
  text-align: center;
}

.add-container {
  display: grid;
  grid-template-columns: 2fr 1fr auto;
  gap: 20px;
  margin-bottom: 8px;

  align-items: center;
}

.add {
  margin-top: 38px;
  line-height: 18px;
}

