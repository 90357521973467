.container {
    padding-bottom: 16px;
}

.list {
    margin: 8px 0;

    display: flex;
    gap: 8px;

    flex-wrap: wrap;
}