.top {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 56px;
}

.links {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.response {
  margin-top: 48px;
  color: var(--global-color-primary-steel-teal);
  text-align: center;
}

@media only screen and (min-width: 1024px) {
  .response {
    /* shift center for large screens */
    padding-right: 128px;
  }
}

.response-actions {
  margin-top: 18px;
  justify-self: center;
  display: flex;
  gap: 56px;
}
