.container {
    border-radius: 20px;
    background-color: var(--global-color-primary-steel-teal);

    padding: 4px 16px;

    color: white;
}

.delete {
    padding: 4px;
    margin-left: 8px;
    margin-bottom: 2px;

    width: 24px;

    vertical-align: middle;

    cursor: pointer;
}
