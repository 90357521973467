@import '@percihealth/react/css/mixins.scss';

.table {
  width: 100%;
}

.table th {
  text-align: left;
  padding-left: 12px
}

.buttons > button {
  margin: 16px 48px 16px 0;
  display: inline-block;
}
