.toggle {
    margin-right: 10px;
    cursor: pointer;
}

.icon {
    width: 24px;
    height: 24px;
    margin-bottom: -6px;
}

.hr {
    padding: 0;
    margin: 0;
}

.menus {
    position: absolute;
    background-color: white;

    display: flex;
    flex-direction: column;
    gap: 4px;
}

.menu {
    padding: 4px 8px;
}