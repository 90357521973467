.container {
  display: flex;
  flex-direction: column;

  margin-bottom: 48px;
}

.checkbox {
  margin-left: 8px;
}
