@import '@percihealth/react/css/mixins.scss';

.container {
  display: block;
  // gap: 2rem;
  // grid-template-areas:
  //   'general appointments'
  //   'other other';
}

.top {
  display: flex;
  justify-content: space-between;
}

.pcp {
  margin-top: 20px;
  margin-left: auto;
  display: block;
}

.expertId {
  display: flex;
  gap: 8px;
  justify-items: stretch;
}
.expertIdInput {
  width: 100%;
}
.saveExpertIdButton {
  padding: 16px 32px;
  min-width: auto;
  align-self: end;
}

.form-header {
  font-size: 16px;
  font-weight: bold;
}

.consent {
  display: flex;
  width: 100%;
}
