.container {
  display: flex;
}

.container.medium {
  gap: 4px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.container.small {
  gap: 2px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.container > * {
  border: solid 1px var(--global-color-grayscale-sonic-silver);
}

.container > *:hover {
  cursor: help;
}

.container.medium > * {
  width: 24px;
  height: 24px;
  border-radius: 12px;
}

.container.small > * {
  width: 16px;
  height: 16px;
  border-radius: 8px;
}

.container .green {
  background-color: var(--global-color-status-green);
}

.container .amber {
  background-color: #ffbf00;
}

.container .red {
  background-color: var(--global-color-status-red);
}

.container .grey {
  background-color: var(--global-color-grayscale-cultured);
}
