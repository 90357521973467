.referral {
  margin-top: 8px;
  margin-bottom: 8px;
}

.review {
  margin-top: 24px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.review-dropdown-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
}


.review-dropdown {
  max-width: 150px;
}
