.add-container {
    display: flex;
    gap: 20px;
}

.add-container > *{
    flex: 2;
}

.add-container > *:first-child {
    flex: 1;
    min-width: 100px;
}

.add {
    align-self: end;
    max-width: 150px;
}