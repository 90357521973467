.container {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  align-items: center;
  gap: 24px;
}

.name-container {
  margin-top: 48px;
}

.name {
  font-weight: bold;
}
.concern {
  font-style: italic;
}
