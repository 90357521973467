.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.qa {
  margin-bottom: 40px;
  p {
    line-height: 16px;
  }
}

.answer {
  font-weight: bold;
  margin-left: 24px;
}

