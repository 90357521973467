.rtw {
    display: flex;
    flex-direction: column;
}

.rtw-grid {
    padding-top: 36px;
    padding-bottom: 16px;
    display: grid;
    grid-template-columns: auto 2fr 3fr;
    gap: 2px 24px;
}

.rtw-grid > * {
    display: flex;
    justify-content: start;
}

.rtw-grid-title {
    font-weight: 600;
}

.rtw-dropdown {
    max-width: 120px;
}

.rtw-notes div, .rtw-notes textarea {
    width: 100%;
}

.rtw-actions {
    display: flex;
    align-self: end;
    margin-bottom: 8px;
    gap: 24px;
}