@import "@percihealth/react/css/mixins.scss";

.table {
    width: 100% !important;
}

.note {
    text-align: center;
}

.filter-container {
    display: grid;
    grid-template-columns: 200px 200px 1fr 2fr 150px;
    justify-content: center;
    margin-bottom: 30px;
    gap: 10px;
    @include media-tablet-horizontal-down {
        display: block;
    }
}
.filter-container > * {
    @include media-tablet-horizontal-down {
        margin-bottom: 10px;
    }
}

.filter-active-container {
    padding-left: 16px;
    display: flex;
    align-items:start;
}

.filter-active__hint {
    margin-top: 4px;
    margin-left: 4px;
}

.status {
    width: 100%;
}

.content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.content-container > * {
    margin: 20px auto;
}

.spinner {
    display: block;
    position: relative;
    margin: 0 auto;
}

.followicon {
    width: 30px;
    height: 30px;
    display: block;
    margin: 0 auto;
}