.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.qa {
  margin-bottom: 40px;
  p {
    line-height: 16px;
  }
}

.answer {
  font-weight: bold;
  margin-left: 24px;
}

.safeguard {
  width: 100%;
  padding-left: 8px;
  display: flex;
  justify-content: space-between;
}

.checkmark {
  margin-top: 8px;
}
