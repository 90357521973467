.container {
  margin-top: 8px;
  max-width: 200px;
}

.question {
  color: grey;
  cursor: pointer;
  &:hover {
    color: var(--global-color-primary-steel-teal);
  }
}
