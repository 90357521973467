.table {
  border-radius: 8px;
}

.table tbody tr td {
  padding-top: 8px;
  padding-bottom: 8px;
}

.note {
  margin-top: 16px;
  padding-left: 8px;
  font-style: italic;
}

.checkbox {
  margin-top: 8px;
}