.container {
    padding: 24px 36px;
    border-radius: 8px;
    text-align: center;
    font-size: 16px;
}

.discharged {
    background: var(--global-color-peach);
}

.submit {
    margin-top: 24px;
}