.header {
    display: flex;
}

.logo {
    align-self: flex-start;
}

.right-block {
    margin-left: auto;
    display: flex;
    align-items: center;
}


.nav {
    margin: 0 40px;
}

.nav a {
    margin-right: 20px;
}