.city {
  padding: 0 0 0 50px;
}

.postal-code {
  padding: 0 50px 0 24px;
}

.submit {
  margin-bottom: 24px;
}
