

.title {
    font-size: 24px;
    font-weight: 300;
    margin-bottom: 8px;
}

.impacts-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap:  56px 32px;
}