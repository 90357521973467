.table {
  width: 100%;
}

.table th {
  text-align: left;
  padding-left: 12px;
}

.table td {
  vertical-align: top;
  padding-top: 4px;
}
