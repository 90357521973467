.group-fullwidth > * {
    flex: 1 1 100%;
    max-width: 100%;
}

.add-container {
    display: flex;
    gap: 20px;

    margin-bottom: 8px;
}

.add-container > *{
    flex: 2;
}

.add-container > *:first-child {
    flex: 1;
    min-width: 100px;
}

.add {
    align-self: end;
    max-width: 150px;
}

.table {
    width: 100% !important; 
    margin: 0 auto 20px 0;
    border-color: var(--global-color-primary-mint);
}
.table tr:nth-child(odd) {
    background: var(--global-color-secondary-white);
}

.table tr:nth-child(even) {
    background: var(--global-color-grayscale-cultured);
}

.table th {
    background: var(--global-color-primary-mint);
}

.table td {
    padding: 0 10px;
    text-align: center;
}
