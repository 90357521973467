@import '@percihealth/react/css/mixins.scss';

.table {
  width: 100%;
}

.table th {
  text-align: left;
  padding-left: 12px
}

.table td {
  padding-top: 8px;
  vertical-align: top;
}

.table td input, .table td textarea {
  margin-top: -8px;
}

.minutes {
  margin-left: auto;
  margin-right: auto;
  max-width: 100px;
}

.actions {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}
